.App {
  text-align: center;

}

.App-logo {
  height: 40vmin;
}

.App-header {
  max-width: 100%;
  overflow: hidden;
  background:#292929;
  min-height: 420vh;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  @media (max-width: 1000px) {
    align-items: center;}
}
.top{
  margin-top: 12vh !important;
  font-size: 3vw !important;
}
.App-link {
  color: #09d3ac;
}

.arrow{
  -webkit-animation: mover 1s infinite  alternate;
  animation: mover 1s infinite  alternate;

}
@-webkit-keyframes mover {
  0% { transform: translateY(0); }
  100% { transform: translateY(-20px); }
}
@keyframes mover {
  0% { transform: translateY(0); }
  100% { transform: translateY(-20px); }
}
